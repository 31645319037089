'use client';

import { Typography, Button, Card, CardContent } from '@mui/material';
import Image from 'next/image';
import { signIn } from 'next-auth/react';

import { useImageVariant } from '@core/hooks/useImageVariant';
import type { Mode } from '@core/types';

import Illustrations from '@/components/Illustrations';
import themeConfig from '@/configs/themeConfig';

const LoginV1 = ({ mode }: { mode: Mode }) => {
  const darkImg = '/images/pages/auth-v2-mask-dark.png';
  const lightImg = '/images/pages/auth-v2-mask-light.png';

  // Hooks
  const authBackground = useImageVariant(mode, lightImg, darkImg);

  return (
    <div className='flex flex-col items-center min-bs-[100dvh] relative p-6'>
      <Card className='flex flex-col sm:is-[450px]'>
        <CardContent className='!p-12'>
          <div className='flex flex-col gap-5'>
            <div>
              <Typography variant='h4'>{`Welcome to ${themeConfig.templateName}!👋🏻`}</Typography>
              <Typography className='mbs-1'>
                Please sign-in to your account and start the adventure
              </Typography>
            </div>
            <form
              noValidate
              autoComplete='off'
              onSubmit={(e) => e.preventDefault()}
              className='flex flex-col gap-5'
            >
              <div className='flex justify-center items-center gap-2'>
                <Button
                  color='secondary'
                  className='self-center text-textPrimary'
                  startIcon={
                    <Image
                      src='/images/logos/google.png'
                      alt='Google'
                      width={22}
                      height={22}
                    />
                  }
                  sx={{ '& .MuiButton-startIcon': { marginInlineEnd: 3 } }}
                  onClick={() => void signIn('google')}
                >
                  Sign in with Google
                </Button>
              </div>
            </form>
          </div>
        </CardContent>
      </Card>
      <Illustrations maskImg={{ src: authBackground }} />
    </div>
  );
};

export default LoginV1;
