export const amazonTokenCookieName = 'x-amzn-marketplace-token';
export const sessionTokenCookieName = 'next-auth.session-token';
export const securedSessionTokenCookieName = `__Secure-${sessionTokenCookieName}`;

export interface Environment {
  isDemo: boolean;
  isDev: boolean;
  clientApiBaseUrl: string;
  exposedTestingGlobals: boolean;
  authToken: string;
  serviceAccountId: string | null;
}

export const defaultEnvironment = {
  isDemo: process.env.IS_DEMO === 'true',
  isDev: Boolean(
    process.env.NODE_ENV === 'development' ||
      process.env.APP_URL?.startsWith('http://localhost:')
  ),
  clientApiBaseUrl: process.env.CLIENT_API_BASE_URL || '',
  exposedTestingGlobals: process.env.EXPOSE_TESTING_GLOBALS === 'true',
  serviceAccountId: process.env.SERVICE_ACCOUNT_ID || null,
};
