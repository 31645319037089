'use client';

import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  type TextFieldProps,
} from '@mui/material';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import { useEnvironment } from '@/contexts/useEnvironment';

export function DomainInput({ inputProps, ...props }: TextFieldProps) {
  return (
    <div className='relative'>
      <TextField
        size='medium'
        placeholder='Add your domain'
        inputProps={{
          ...inputProps,
          style: { height: '36px', paddingRight: 130 },
        }}
        {...props}
      />
      <Typography
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: 20,
          width: 100,
        }}
      >
        .console2.eon.io
      </Typography>
    </div>
  );
}

export function LoginPage() {
  const [domain, setDomain] = useState('');
  const { clientApiBaseUrl } = useEnvironment();

  useEffect(() => {
    if (window.location.href.split('.').length === 4) {
      window.location.href = `${clientApiBaseUrl}/login`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLogin = () => {
    if (!domain) return;
    window.location.replace(
      `${clientApiBaseUrl}/login?domain=${domain}&returnUrl=${window.location.origin}`
    );
  };

  return (
    <Box className='m-auto'>
      <Typography variant='h1' className='font-extralight text-6xl text-center'>
        Login to Eon
      </Typography>
      <Box className='flex justify-center content-center mt-[24px]'>
        <DomainInput
          inputProps={{ 'data-testid': 'login-domain' }}
          value={domain}
          sx={{ width: '320px' }}
          onChange={(event) => setDomain(event.target.value)}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              onLogin();
            }
          }}
        />

        <Button
          className='px-[20px] ml-[16px]'
          disabled={!domain}
          data-testid='login-button'
          variant='contained'
          onClick={onLogin}
        >
          Login
          <i className='material-symbols-arrow-right-alt ml-[8px] align-middle' />
        </Button>
      </Box>
      <Divider
        sx={{
          width: 600,
          margin: '40px auto',
          borderColor: 'var(--mui-palette-background-lightGray)',
        }}
      />
      <Box className='text-center'>
        {`Don't have an account? `}
        <Link className='font-bold' href='/register'>
          Sign Up
        </Link>
      </Box>
    </Box>
  );
}
