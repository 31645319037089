'use client';

import { createContext, useState } from 'react';
import type { PropsWithChildren } from 'react';

import {
  securedSessionTokenCookieName,
  sessionTokenCookieName,
  type Environment,
} from './environment';

export const EnvironmentContext = createContext<
  (Environment & { updateAuthToken: () => void }) | null
>(null);

export const EnvironmentProvider = ({
  environment,
  children,
}: PropsWithChildren<{
  environment: Environment;
}>) => {
  const [env, setEnv] = useState(environment);

  return (
    <EnvironmentContext.Provider
      value={{
        ...env,
        updateAuthToken: () => {
          const token =
            getCookie(securedSessionTokenCookieName) ||
            getCookie(sessionTokenCookieName);

          setEnv({ ...env, authToken: token! });
        },
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};

function getCookie(key: string) {
  const b = document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : '';
}
