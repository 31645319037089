import { useColorScheme } from '@mui/material';
import { useMemo } from 'react';

import type { Mode } from '@core/types';

export const useImageVariant = (
  mode: Mode,
  imgLight: string,
  imgDark: string
): string => {
  // Hooks
  const { mode: muiMode, systemMode: muiSystemMode } = useColorScheme();

  return useMemo(() => {
    const isServer = typeof window === 'undefined';

    const currentMode = (() => {
      if (isServer) return mode;

      return muiMode === 'system' ? muiSystemMode : muiMode;
    })();

    const isDarkMode = currentMode === 'dark';

    return isDarkMode ? imgDark : imgLight;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, muiMode, muiSystemMode]);
};
